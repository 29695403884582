import types from "../actions/types";

const defaultState = {
  casinoList: [],
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.CASINO_LIST:
      return {
        ...state,
        casinoList: action.casinoList,
      };
    default:
      return state;
  }
};
