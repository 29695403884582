import React from "react";
import { CustomButton } from "./style";
import { Props } from "./type";
import useWindowSize from "hooks/useWindowSize";
const TextButton: React.FC<Props> = ({
  children,
  onClick,
  type = "button",
  variant = "light",
  height = 50,
  className = "",
  active,
  disabled
}) => {
  const size = useWindowSize();
  return (
    <div className={`text-btn`} style={{ maxWidth: 400, width: "100%" }}>
      <CustomButton
        disabled={disabled}
        style={{ height: size.width && size.width > 425 ? height : 40 }}
        className={`${variant} ${className} ${active ? 'active': ''}`}
        type={type}
        onClick={onClick}
      >
        {children}
      </CustomButton>
    </div>
  );
};

export default TextButton;
