import React from "react"; //lazy
import { Layout } from "antd";
import { useHistory } from "react-router-dom";

import { UrlInternal } from "common/constants/endpoints";

import * as S from "./style";

const { Footer } = Layout;

interface Props {
  onClick?: any;
  className?: any;
}

const data = [
  { id: 0, label: "조합배팅", link: UrlInternal.SPORT_SOLO_ROUTER },
  { id: 1, label: "스페셜", link: UrlInternal.SPORT_SPECIAL_ROUTER },
  { id: 2, label: "실시간", link: UrlInternal.SPORT_LIVE_ROUTER },
  { id: 3, label: "라이브 카지노", link: UrlInternal.CASINO },
  { id: 4, label: "슬롯게임", link: UrlInternal.SLOT_GAME },
  { id: 5, label: "파워볼게임", link: UrlInternal.MINI_GAME },
  { id: 6, label: "경기결과", link: UrlInternal.HISTORY_SPORT_SOLO },
  { id: 7, label: "배팅내역", link: UrlInternal.HISTORY_BETTING_POWER },
  { id: 8, label: "포인트전환", link: UrlInternal.POINT },
  { id: 9, label: "공지사항", link: UrlInternal.NOTIFICATION },
  { id: 10, label: "고객센터", link: UrlInternal.CUSTOMER_CENTER },
];

const BoxLink = (props: Props) => {
  const history = useHistory();
  const { onClick, className } = props;

  const linkGame = (link: string) => {
    history.push(link);
  };

  return (
    <S.Wrapper>
      <S.BoxMoney>
        <div className="item" onClick={() => history.push(UrlInternal.DEPOSIT)}>
          <i className="fa fa-plus-square text-primary"></i> 입금
        </div>
        <div className="item" onClick={() => history.push(UrlInternal.PAYMENT)}>
          <i className="fa fa-minus-square text-danger"></i> 출금
        </div>
      </S.BoxMoney>
      <S.LinkBox>
        {data.map((v: any, i: number) => (
          <div key={i} onClick={() => linkGame(v?.link)} className="item-link">
            {v.label}
          </div>
        ))}
      </S.LinkBox>
    </S.Wrapper>
  );
};

export default BoxLink;
