/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-06-16 16:56:20
 * @modify date 2022-06-16 16:56:20
 * @desc [description]
 */

export enum UrlInternal {
  // Name Page
  HOME = "/",
  LOGIN = "/login",
  REGISTER = "/register",
  USER = "/user",
  PAYMENT = "/payment",
  DEPOSIT = "/deposit",
  POINT = "/point",
  NOTIFICATION = "/notification",
  NOTIFICATION_DETAIL = "/notification/detail/:id",
  NOTIFICATION_DETAIL_ROUTER = "/notification/detail/",
  EVENT = "/event",
  EVENT_DETAIL = "/event/detail/:id",
  EVENT_DETAIL_ROUTER = "/event/detail/",
  CUSTOMER_CENTER = "/customer-center",
  CUSTOMER_CENTER_REGISTER = "/customer-center/register",
  CUSTOMER_CENTER_DETAIL = "/customer-center/detail/:id",
  CUSTOMER_CENTER_DETAIL_ROUTER = "/customer-center/detail/",
  BET = "/bet",
  MESSAGE = "/messages",
  CASINO = "/live",
  CASINO_DETAIL = "/live/:id",
  CASINO_DETAIL_ROUTER = "/live/",
  SLOT_GAME = "/rng",
  SLOT_DETAIL = "/rng/:id",
  SLOT_DETAIL_ROUTER = "/rng/",
  MINI_GAME = "/mini_lobby",
  MINI_GAME_DETAIL = "/mini_lobby/detail/:id",
  MINI_GAME_DETAIL_ROUTER = "/mini_lobby/detail/",
  SPORT = "/sports",
  SPORTS_DETAIL = "/sports/detail/:id",
  SPORTS_DETAIL_ROUTER = "/sports/detail/",
  SPORT_SOLO_ROUTER = "/sports/detail/0",
  SPORT_SPECIAL_ROUTER = "/sports/detail/3",
  SPORT_LIVE_ROUTER = "/sports/detail/r",

  // partner
  PARTNER = "/partner",
  PARTNER_RATE_DETAIL = "/partner/rate/:id",
  PARTNER_RATE_DETAIL_ROUTER = "/partner/rate/",
  PARTNER_CHILD_DETAIL = "/partner/child/:id",
  PARTNER_CHILD_DETAIL_ROUTER = "/partner/child/",
  PARTNER_ADD_CHILD_DETAIL = "/partner/add_child/:id",
  PARTNER_ADD_CHILD_DETAIL_ROUTER = "/partner/add_child/",
  PARTNER_DEPOSIT_LIST = "/partner/deposit_list/:id",
  PARTNER_WITHDRAW_LIST = "/partner/withdraw_list/:id",
  PARTNER_BETTING_LIST = "/partner/betting_list/:id",
  PARTNER_ROLLING_LIST = "/partner/rolling_list/:id",
  PARTNER_PARTNER_DEPOSIT_LIST = "/partner/partner_deposit_list/:id",
  PARTNER_PARTNER_WITHDRAW_LIST = "/partner/partner_withdraw_list/:id",
  PARTNER_PARTNER_DEPOSIT_LIST_ALL = "/partner/partner_deposit_list_all/:id",
  PARTNER_PARTNER_WITHDRAW_LIST_ALL = "/partner/partner_withdraw_list_all/:id",
  PARTNER_CHILD_LIST = "/partner/child_list/:id",
  PARTNER_DEPOSIT_RANKING = "/partner/deposit_ranking/:id",
  PARTNER_WITHDRAW_RANKING = "/partner/withdraw_ranking/:id",
  PARTNER_BETTING_RANKING = "/partner/betting_ranking/:id",
  PARTNER_BETTING_RANKING_DETAIL = "/partner/betting_ranking/detail/:id",
  PARTNER_BETTING_RANKING_DETAIL_ROUTER = "/partner/betting_ranking/detail/",
  PARTNER_ROLLING_RANKING = "/partner/rolling_ranking/:id",
  PARTNER_DEPOSIT_MONEY = "/partner/deposit_money/:id",
  PARTNER_WITHDRAW_MONEY = "/partner/withdraw_money/:id",
  PARTNER_DEPOSIT_MONEY_ROUTER = "/partner/deposit_money/",
  PARTNER_WITHDRAW_MONEY_ROUTER = "/partner/withdraw_money/",

  // HISTORY_BETTING = "/history-betting",
  HISTORY_BETTING_POWER = "/history-betting-power",
  HISTORY_BETTING_SOLO = "/history-betting-solo",
  HISTORY_BETTING_SPECIAL = "/history-betting-special",
  HISTORY_BETTING_LIVE = "/history-betting-live",

  // HISTORY_SPORT = "/history-sport",
  HISTORY_SPORT_SOLO = "/history-sport-solo",
  HISTORY_SPORT_SPECIAL = "/history-sport-special",
  HISTORY_SPORT_LIVE = "/history-sport-live",

  BLANK = "/blank/:id",
  BLANK_ROUTER = "/blank/",
}
