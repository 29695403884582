import styled from "styled-components";

export const Wrapper = styled.div`
  // width: 100%;
  width: 360px;
  background: #020135;
  border: 1px solid #7c79ff;
  padding: 10px;
  border-radius: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin: 10px auto;

  .bestlip {
    background: #382275;
    // font-family: "SCDream4";
    font-size: 16px;
    // font-weight: 700;
    padding: 10px 15px;
    // border-bottom: 1px solid #32363a;
    color: #fff;
  }
`;

export const InfoBetWrapper = styled.div`
  width: 100%;
  display: flex;
  color: #fff;
  height: 117px;
  border-radius: 10px;
  border: 1px solid var(--Stroke-Menu-button, #7c79ff);
  padding: 15px;
  background: var(--Bg, #010023);
`;

export const InfoBetColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  text-align: center;
  justify-content: center;
  gap: 10px;
  .item {
    height: 25px;
    font-size: 16px;
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div`
  width: 100%;
  padding: 0 11px 0 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border-bottom: 1px solid #7c79ff;
  // padding: 10px 8px;
  // background: #d2d8e0;
  &.nBillMoney {
    // background: #057575 0% 0% no-repeat padding-box;
    span {
      border: 1px solid var(--Stroke-Menu-button, #7c79ff);
      background: #382275;
      // background: #382275 0% 0% no-repeat margin-box;
      padding: 11px;
    }
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  input,
  span {
    font-size: 18px;
    width: 70%;
    text-align: right;
    background: transparent;
    border: unset;
    outline: none;
    padding: 11px;
  }
`;

export const ButtonMoneyWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // flex-wrap: wrap;
  // justify-content: space-between;
  height: auto;
  gap: 10px;
  padding-bottom: 5px;
  margin: 10px 0;
`;

export const ButtonSubmit = styled.div`
  width: 100%;
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  // button {
  //   width: calc(100% / 2 - 4px);
  //   border-radius: 4px;
  //   color: #fff;
  //   font-size: 15px;
  //   font-weight: 500;
  //   padding: 10px 0;
  //   margin-bottom: 0;
  //   margin-right: 0;
  // }
`;

export const Text = styled.span`
  font-size: 15px;
`;
