import styled from "styled-components";


export const Wrapper = styled.div`
    width: 100%;
    max-width: 400px;
    position: relative;
    font-size: 16px;
`

export const Input = styled.input`
    width: 100%;
    height: 50px;
    border: 1px solid var(--Stroke-Menu-button, #7C79FF);
    background: var(--Dark-Purple, #020135);
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    padding-left: 16px;
    &:focus{
        outline: none;
    }
    @media screen and (max-width: 425px) {
        height: 40px;
    }
`
