/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2021-07-19 09:56:23
 * @modify date 2022-08-30 13:59:03
 * @desc [description]
 */

export const TABLE_USER = "User";
export const TABLE_USER_COLUMN = "UserInfo";

export const GETME = "ART_GET_ME"; // user info
export const AVATAR = "AVATAR";
export const TOKEN = "TOKEN";
