import toggleIcon from 'assets/icons/toggle.svg'
import userIcon from 'assets/icons/user.svg'
import logo from 'assets/images/login/logo.png'
import { UrlInternal } from 'common/constants/endpoints'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FloatMenu from './FloatMenu'
import { MobileNav } from './style'
const MobileHeader = () => {
    const [toggle, setToggle] = useState(false)

    useEffect(() =>{
        document.body!.style.overflow = 'auto'
    }, [])

    const handleOpen = () =>{
        setToggle(true);
        setTimeout(() =>{
            document.getElementById('mobileMenu')?.classList.add('show')
            document.body!.style.overflow = 'hidden'
        }, 0)
    }

    const handleClose = () =>{
        document.getElementById('mobileMenu')?.classList.remove('show')
        document.body!.style.overflow = 'auto'
        setTimeout(() =>{
            setToggle(false)
        }, 300)
    }

    return (
        <>
            <MobileNav>
                <div className='toggle' onClick={handleOpen}>
                    <img src={toggleIcon} alt='' />
                </div>
                <Link to={UrlInternal.HOME} className='logo'>
                    <img src={logo} alt='' />
                </Link>
                <Link className='user' to={UrlInternal.USER}>
                    <img src={userIcon} alt='' />
                </Link>
            </MobileNav>
            {
                toggle && <FloatMenu
                    show={toggle}
                    onClose={handleClose}
                />
            }
        </>
    )
}

export default MobileHeader
