import Loading from "containers/Loading";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import initStore from "store";
import App from "./App";
import { SearchBarHeader } from "./components";
import * as serviceWorker from "./serviceWorker";
const store = initStore();

function getLoading() {
  setInterval(() => {}, 2000);
  return <Loading />;
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <Suspense fallback={getLoading()}>
        {/* <Loading /> */}
        <App />
        <SearchBarHeader />
      </Suspense>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
