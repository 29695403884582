import React, { useEffect, useMemo, useState } from "react"; //lazy
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { UrlInternal } from "common/constants/endpoints";
import logo from "assets/images/login/logo.png";
import LogoHome from "assets/images/home/home-t1.png";
import ButtonMenu from "components/ButtonBox/ButtonMenu";
import iconHomeHover from "assets/images/home/iconHomeHover.png";
import * as S from "./style";
import { miniAllApi } from "apis";

import homeIcon from 'assets/icons/home.svg'
import sport from 'assets/icons/sport.svg'
import realtime from 'assets/icons/real-time.svg'
import casino from 'assets/icons/casino.svg'
import slot from 'assets/icons/slot.svg'
import powerBall from 'assets/icons/power-ball.svg'
import useWindowSize from "hooks/useWindowSize";
import MobileMenu from "./MobileMenu";
import Clock from "./Clock/Clock";

const headerMenu = [
  {
    id: 0,
    label: "조합배팅",
    link: UrlInternal.SPORT_SOLO_ROUTER,
  },
  {
    id: 1,
    label: "스페셜",
    link: UrlInternal.SPORT_SPECIAL_ROUTER,
  },
  {
    id: 2,
    label: "실시간",
    link: UrlInternal.SPORT_LIVE_ROUTER,
  },
  {
    id: 3,
    label: "라이브 카지노",
    link: UrlInternal.CASINO,
  },
  {
    id: 4,
    label: "슬롯게임",
    link: UrlInternal.SLOT_GAME,
  },

  {
    id: 5,
    label: "파워볼게임",
    link: UrlInternal.MINI_GAME,
  },
  {
    id: 6,
    label: "고객센터",
    link: UrlInternal.CUSTOMER_CENTER,
  },
  {
    id: 7,
    label: "공지사항",
    link: UrlInternal.NOTIFICATION,
  },
  {
    id: 8,
    label: "배팅내역",
    link: UrlInternal.HISTORY_BETTING_POWER,
  },
  {
    id: 9,
    label: "경기결과",
    link: UrlInternal.HISTORY_SPORT_SOLO,
  },
];

const HeaderPage = (props) => {
  const history = useHistory();
  const { onClick, className } = props;
  const dataMe = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const [listMiniGame, setListMiniGame] = useState([]);
  const dispatch = useDispatch();
  const size = useWindowSize();
  const menuNavbar = useMemo(() => {
    return [
      {
        id: 0,
        label: "홈",
        link: UrlInternal.HOME,
        icon: homeIcon,
        active: false,
      },
      {
        id: 1,
        label: "국내형 스포츠",
        link: UrlInternal.SPORT_SPECIAL_ROUTER,
        icon: sport,
        active: false,
      },
      {
        id: 2,
        label: "실시간",
        link: UrlInternal.SPORT_LIVE_ROUTER,
        icon: realtime,
        active: false,
      },
      {
        id: 3,
        label: "라이브 카지노",
        link: UrlInternal.CASINO,
        icon: casino,
        active: false,
      },
      {
        id: 4,
        label: "슬롯 머신",
        link: UrlInternal.SLOT_GAME,
        icon: slot,
        active: false,
      },
      {
        id: 5,
        label: "파워볼",
        link: `${UrlInternal.MINI_GAME_DETAIL_ROUTER}${listMiniGame.length > 0 && listMiniGame[0].game_type
          }`,
        icon: powerBall,
        active: false,
      },
    ];
  }, [listMiniGame]);

  const Logout = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("Popup");
    history.push(UrlInternal.LOGIN);
  };

  const getListMiniGame = async () => {
    dispatch({ type: "WAITTING" });
    try {
      const res = await miniAllApi();

      if (res?.result === false) {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
        return;
      }

      setListMiniGame(res);
      dispatch({ type: "STOP_WAITTING" });
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    } finally {
      dispatch({ type: "STOP_WAITTING" });
    }
  };

  useEffect(() => {
    if (dataMe) setUser({ ...dataMe.dataUser });
  }, [dataMe]);

  useEffect(() => {
    getListMiniGame();
  }, []);

  return (
    <>
      {
        size.width && size.width > 1200 ?
          <S.Header>
            {/* <S.Wrapper> */}
            <S.WrapperTop>
              <S.WrapperLeft>
                <S.TopLogo onClick={() => history.push(UrlInternal.HOME)}>
                  <img alt="logo" src={logo} />
                </S.TopLogo>
                <S.WrapperTime><Clock/></S.WrapperTime>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.MESSAGE || history.location.pathname === UrlInternal.EVENT ? 'active' : ''}
                  onClick={() => history.push(UrlInternal.MESSAGE)} color={"#FFB342"} mode>
                  쪽지
                </S.BtnLabel>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.HISTORY_SPORT_SOLO || history.location.pathname === UrlInternal.HISTORY_SPORT_SPECIAL || history.location.pathname === UrlInternal.HISTORY_SPORT_LIVE? 'active' : ''}
                  onClick={() => history.push(UrlInternal.HISTORY_SPORT_SOLO)}>경기결과</S.BtnLabel>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.HISTORY_BETTING_SOLO || history.location.pathname === UrlInternal.HISTORY_BETTING_SPECIAL || history.location.pathname === UrlInternal.HISTORY_BETTING_LIVE || history.location.pathname === UrlInternal.HISTORY_BETTING_POWER? 'active' : ''}
                  onClick={() => history.push(UrlInternal.HISTORY_BETTING_SOLO)}>배팅내역</S.BtnLabel>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.CUSTOMER_CENTER ? 'active' : ''}
                  onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}>
                  1:1 문의
                </S.BtnLabel>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.DEPOSIT ? 'active' : ''}

                  onClick={() => history.push(UrlInternal.DEPOSIT)}
                  color={"#FF5D2D"}
                  mode
                >
                  충전하기
                </S.BtnLabel>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.PAYMENT ? 'active' : ''}

                  onClick={() => history.push(UrlInternal.PAYMENT)}
                  color={"#2DA1FF"}
                  mode
                >
                  환전하기
                </S.BtnLabel>
                <S.BtnLabel
                  className={history.location.pathname === UrlInternal.POINT ? 'active' : ''}

                  onClick={() => history.push(UrlInternal.POINT)}
                  color={"#AEFF33"}
                  mode
                >
                  포인트 전환
                  
                </S.BtnLabel>
              </S.WrapperLeft>
              <S.TopButton>
                <S.BtnLabel
                  // onClick={() => history.push(UrlInternal.DEPOSIT)}
                  color={"#FFB342"}
                >
                  {user?.mb_name}
                </S.BtnLabel>
                <S.BtnLabel onClick={() => history.push(UrlInternal.DEPOSIT)}>
                  보유 잔액 {user?.mb_money}원
                </S.BtnLabel>
                <S.BtnLabel
                  // onClick={() => history.push(UrlInternal.DEPOSIT)}
                  color={"#FFB342"}
                >
                  포인트 {user?.mb_point}P
                </S.BtnLabel>
                {/* <S.BtnLabel
                        onClick={() => history.push(UrlInternal.PAYMENT)}
                    >
                        출금
                    </S.BtnLabel> */}
                {/* <S.BtnLabel
                        onClick={() => history.push(UrlInternal.MESSAGE)}
                        color={'#FFB342'}
                    >
                        쪽지 ({dataMe?.dataUser?.count_message})
                    </S.BtnLabel> */}
                {/* {dataMe?.dataUser?.mb_option_rec === "1" && (
                  <S.BtnLabel
                    onClick={() => window.open(UrlInternal.PARTNER)}
                    color={"#AEFF33"}
                  >
                    파트너 페이지
                  </S.BtnLabel>
                )} */}

                <S.BtnLabel onClick={Logout} color={"#FF7272"}>
                  로그아웃
                </S.BtnLabel>
              </S.TopButton>
            </S.WrapperTop>
            {/* </S.Wrapper> */}
            {/* <S.Wrapper>
                <S.WrapperBot>
                    {headerMenu.map((v, i) => {
                        return (
                            <div className='item' key={i}>
                                <div
                                    className='content'
                                    onClick={() => history.push(v.link)}
                                >
                                    {v.label}
                                </div>
                            </div>
                        );
                    })}
                </S.WrapperBot>
            </S.Wrapper> */}
            {/* <S.WrapperNavbar>
                <S.NavbarList>
                    {menuNavbar.map((v, i) => {
                        return (
                            <S.ItemNavbar
                                className='item active'
                                key={i}
                                onClick={() => history.push(v.link)}
                            >
                                <img alt='logo' src={v.icon} />
                                {v.label}
                            </S.ItemNavbar>
                        );
                    })}
                </S.NavbarList>
            </S.WrapperNavbar> */}
            <S.WrapperNavbar>
              <S.NavbarList>
                {menuNavbar.map((v, i) => {
                  return (
                    <ButtonMenu
                      key={i}
                      onClick={() => history.push(v.link)}
                      className={`flex-column ${history.location.pathname === v.link ||
                        history.location.pathname.includes(v.link === '/' || v.link) ? 'active' : ''}`}
                      title={v.label}
                      imgSrc={v.icon}
                      imgSrcHover={iconHomeHover}
                    />
                  );
                })}
              </S.NavbarList>
            </S.WrapperNavbar>
          </S.Header>
          : <MobileMenu></MobileMenu>
      }

    </>
  );
};

export default HeaderPage;
