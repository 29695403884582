import React from "react";
import { Input, Wrapper } from "./style";
import {Props} from './type';

const CustomInput: React.FC<Props> = ({initValue, placeHolder, onChange, type = 'text', readonly, ...rest}) =>{
    return (
        <Wrapper className="custom-input">
            <Input {...rest} readOnly={readonly} type={type} onChange={onChange} placeholder={placeHolder} defaultValue={initValue} value={initValue} />
        </Wrapper>
    )
}

export default CustomInput
