import React, { useEffect, useState } from 'react'; //lazy
import { useHistory, useLocation } from 'react-router-dom';

// import ImgLogo from "assets/images/imgHeader/logo.png";
// import IconUser from "assets/images/imgHeader/IconUser.png";
// import ImgSlider from "assets/images/_group_sale/img_slider.png";
// import { ReactComponent as IconMenu } from "assets/icons/align-justify.svg";
// import { ReactComponent as IconCloseMenu } from "assets/icons/close.svg";

import { deleteLocaStorage, saveLocalStorage } from 'utils/localStorage';
import { UrlInternal } from 'common/constants/endpoints';
import { useDispatch } from 'react-redux';
// import { listMenu } from "components/Menu";

import { GETME } from 'utils/localStorage/type';
import types from 'store/actions/types';
import * as S from './style';
import NewContainer from '../container';
import { useSelector } from 'react-redux';
import {
    HeaderPage,
    BoxLink,
    ListGame,
    ListSport,
    BoxInfo,
    CasinoList,
    FooterPage,
    BestSlipMiniGame,
} from 'components';
interface Props {
    children?: any;
    noImageHeader?: boolean;
    type?: 'smallBanner' | 'default';
    typeContent?: 'out' | 'in';
    outSideLayout?: React.ReactNode;
}

const TemplateMypage = ({
    children,
    noImageHeader,
    type = 'default',
    typeContent = 'in',
    outSideLayout,
}: Props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const userData: any = localStorage.getItem('User');
    const dataMe = useSelector((state: any) => state.user);

    return (
        <S.Wraper>
            <HeaderPage />
            {outSideLayout}
            <NewContainer>{children}</NewContainer>
            {/* <S.Container>
        <S.LeftMenu>
          <BoxLink />
          <ListGame />
          <ListSport />
        </S.LeftMenu>
        <S.Children>
          {children}
          <FooterPage />
        </S.Children>
        <S.RightMenu>
          <BoxInfo />
          <BestSlipMiniGame />
          <CasinoList />
        </S.RightMenu>
      </S.Container> */}
        </S.Wraper>
    );
};

export default TemplateMypage;
