import React from "react";
import { useSelector } from "react-redux";
import { CustomModal } from './style';
import ModalCustomerCenter from "./layout/CS";
const MyModal: React.FC = () => {
  const { open, name } = useSelector((state: any) => state.modal)

  console.log(name);
  
  return (
    open && (
      <CustomModal width={'fit-content'} open={open} closeIcon={''} closable={false} footer={null} >
        {name === '1:1' && <ModalCustomerCenter />}
      </CustomModal>
    )
  )
}

export default MyModal
