import React, { useState, useEffect } from "react"; //lazy
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import { useDispatch } from "react-redux";

import { UrlInternal } from "common/constants/endpoints";
import { GameRightApi } from "apis";

import chrome from "assets/images/templates/chrome_down.png";
import bn1 from "assets/images/templates/banner-right-01.gif";
import bn2 from "assets/images/templates/banner-right-02.gif";
import bn3 from "assets/images/templates/banner-right-03.gif";
import bn4 from "assets/images/templates/banner-right-04.gif";

import * as S from "./style";

const { Footer } = Layout;

interface Props {
  onClick?: any;
  className?: any;
}

const data = [
  { id: 0, img: bn1, link: UrlInternal.SPORT_SOLO_ROUTER },
  { id: 1, img: bn2, link: UrlInternal.MINI_GAME },
  { id: 2, img: bn4, link: UrlInternal.CASINO },
  { id: 3, img: bn3, link: UrlInternal.SLOT_GAME },
];

const CasinoList = (props: Props) => {
  const { onClick, className } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataImg, setDataImg] = useState<any>([]);

  const getGameRight = async () => {
    dispatch({ type: "WAITTING" });
    try {
      const res: any = await GameRightApi({
        type: "right",
      });
      // console.log(res);
      if (res.result === false) {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
      } else {
        setDataImg(res);
        dispatch({ type: "STOP_WAITTING" });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    }
  };

  useEffect(() => {
    getGameRight();
  }, []);

  function openGoogle(doFocus: any) {
    let myGoogle: any = window.open(
      `https://www.google.com/chrome/`,
      "myGoogle"
    );
    if (doFocus) {
      myGoogle.focus();
    }
  }

  return (
    <S.Wrapper>
      <S.Chrome onClick={() => openGoogle(true)}>
        <div className="img">
          <img alt="" src={chrome} />
        </div>
        <div className="text">
          본 사이트는
          <span>크롬 브라우저</span>에 최적화 되어 있으므로 크롬에서
          확인해주세요.
        </div>
      </S.Chrome>
      <S.ButtonBox>
        {data?.map((v: any, i: number) => (
          <div className="item" key={i}>
            <img alt="" src={v.img} onClick={() => history.push(v.link)} />
          </div>
        ))}
      </S.ButtonBox>
    </S.Wrapper>
  );
};

export default CasinoList;
