import styled from "styled-components";

export const ModalLayout = styled.div`
    width: 50vw;
    border-radius: 10px;
    background: var(--Purple, #382275);
    padding: 40px 50px;
    position: relative;
    @media screen and (max-width: 425px) {
        width: 90vw;
        padding: 48px 15px 20px;
    }
    .icon{
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 10px;
        @media screen and (max-width: 425px) {
            top: 13px;
            right: 15px;
        }
    }
    .text-btn{
        margin: auto;
    }
    form{
        .form-item{
            label{
                font-size: 20px;
                font-weight: 700;
                display: flex;
                color: #FFB342;
                span{
                    color: #E2342F;
                    display: block;
                    margin-top: -5px;
                }
            }
            .custom-input{
                margin-top: 10px;
                max-width: 100%;
            }
            textarea{
                width: 100%;
                height: 10em;
                border: 1px solid var(--Stroke-Menu-button,#7C79FF);
                background: var(--Dark-Purple,#020135);
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                &:focus{
                    outline: none;
                }
            }
            .items-bet{
                border: 0;
            }
        }
    }
`
