import styled from 'styled-components';
import theme from 'common/style/styleSetup';

export const ItemNavbar = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0 12px;
    color: #ffb342;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    &.active {
        border-right: 2px solid #7c79ff;
        border-left: 2px solid #7c79ff;
        background: linear-gradient(
            180deg,
            #543db2 0%,
            #7a4a8b 48.96%,
            #ff7a00 100%
        );
    }
    img {
        color: #ff7a00;
        filter: #ff7a00;
    }
`;
export const NavbarList = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
`;

export const WrapperNavbar = styled.div`
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #7c79ff;
    background: #020135;
`;

export const WrapperTime = styled.div`
    display: flex;
    color: #fff;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
export const BtnLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;

    color: ${(prop) => prop.color ?? '#FFFFFF'};
    &.active{
        color: #FF5D2D;
        background: ${(prop) => (prop.mode ? '#020135' : 'initial')};
    }
    /* border-radius: 4px;
  border: 1px solid #7c79ff;
  background: #543db2;
  box-shadow: 3px 4px 4px 0px rgba(189, 137, 255, 0.54) inset,
    -3px -5px 4px 0px rgba(0, 0, 0, 0.25) inset; */

    padding: 8px;
    border-radius: 4px;
    text-shadow: 1px 1px 4px #000;
    border: ${(prop) => (prop.mode ? '1px solid #7c79ff' : 'initial')};
    background: ${(prop) => (prop.mode ? '#543db2' : 'initial')};
    box-shadow: ${(prop) =>
        prop.mode
            ? '3px 4px 4px 0px rgba(189, 137, 255, 0.54) inset, -3px -5px 4px 0px rgba(0, 0, 0, 0.25) inset'
            : 'initial'};
`;

export const WrapperLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blueviolet; */
    gap: 12px;
`;

export const Header = styled.div`
    background-color: #050511;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #382275;
`;

export const Wrapper = styled.div`
    width: 100%;
    border-top: solid 1px 1px ${theme.bg[9]};
    border-bottom: solid 1px 1px ${theme.bg[9]};
    background-image: linear-gradient(#23344a, #13253e);
    box-shadow: inset 0 3px 0 #0e151e;
`;

export const WrapperTop = styled.div`
    /* max-width: 1350px; */
    width: 100%;
    height: 55px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 12px;
`;

export const TopLogo = styled.div`
    cursor: pointer;
    @keyframes logoAkAnim {
        0% {
            opacity: 0;
            transform: translateY(50%);
        }
        5% {
            opacity: 0;
            transform: translateY(50%);
        }
        15% {
            opacity: 1;
            transform: translateY(0);
        }
        90% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(0);
        }
    }
    img {
        /* width: 210px; */
        // width: 150px;
        // height: 50px;
        animation: logoAkAnim 8s ease infinite;
    }
`;

export const TopButton = styled.div`
    display: flex;
    gap: 8px;
    .content-top {
        height: 25px;
        font-weight: 600;
        color: ${theme.color[0]};
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 1px 8px 0;
        background: ${theme.bg[10]};
        border: solid 1px ${theme.bg[13]};
        border-radius: 4px;

        /* font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
        &.logout {
            background: ${theme.bg[11]};
            border: solid 1px ${theme.bg[14]};
            border: none;
        }
        :hover {
            background: ${theme.bg[13]};
            cursor: pointer;
            &.logout {
                background: ${theme.bg[12]};
            }
        }
        .fa {
            margin: -3px 4px 0 0;
        }
    }
`;

export const WrapperBot = styled.div`
    max-width: 1350px;
    width: 100%;
    height: 46px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .item {
        color: ${theme.color[7]};
        width: 10%;
        height: 24px;
        padding: 0 12px;
        text-align: center;
        border-left: 1px solid ${theme.bg[15]};
        justify-content: center;
        display: flex;
        align-items: center;
        :nth-child(10) {
            border-right: 1px solid ${theme.bg[15]};
        }
        .content {
            width: 100%;
            height: 28px;
            padding-top: 1px;
            line-height: 28px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            :hover {
                background: ${theme.bg[16]};
                border: solid 1px ${theme.bg[17]};
            }
        }
    }
`;
