import types from "../actions/types";

const defaultState = {
  slotGameList: [],
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.SLOT_GAME_LIST:
      return {
        ...state,
        slotGameList: action.slotGameList,
      };
    default:
      return state;
  }
};
