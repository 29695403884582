import styled from 'styled-components'
export const MobileNav = styled.div`
  background: var(--Purple, #382275);
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: space-between;
`

export const Menu = styled.div`
  position: absolute;
  z-index: 20;
  width: 55vw;
  height: 100%;
  top: 0;
  left: calc(-55vw - 45px);
  background: var(--Dark-Purple, #020135);
  backdrop-filter: blur(4.5px);
  transition: all 0.3s;
  overflow: auto;
  &.show {
    left: 0;
  }
  ul{
    padding: 0;
    li{
      list-style: none;
      a{
        font-size: 16px;
        padding: 20px 0 20px 20px;
        display: block;
        border-bottom: 1px solid var(--Stroke-Menu-button, #7C79FF);
      }
    }
  }
`

export const BackDrop = styled.div`
  position: fixed;
  z-index: 19;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`
