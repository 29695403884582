import React from 'react'; //lazy
import * as S from './style';

interface Props {
    onClick?: any;
    className?: any;
    children?: any;
    imgSrc?: any;
    title?: any;
    imgSrcHover: any;
}

const ButtonMenu = (props: Props) => {
    const { onClick, className, children, imgSrc, title, imgSrcHover } = props;

    return (
        <S.Wrapper className={className} onClick={onClick ?? null}>
            {children}
            <div
                style={{
                    overflow: 'hidden',
                    maxHeight: '30px',
                    height: '30px',
                }}
            >
                <img className='img' src={imgSrc} alt='' />
            </div>

            <span className='title'>{title}</span>
        </S.Wrapper>
    );
};

export default ButtonMenu;
