import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from 'assets/images/login/logo.png'
import * as S from "./style";
interface Props {
  className?: string;
}

const PopupCheck = ({ className }: Props) => {
  const dispatch = useDispatch();
  const popupStates = useSelector((state: any) => state.waitting);
  // console.log(popupStates);

  useEffect(() => {
    if (popupStates.isWaitting) {
      setTimeout(() => {
        dispatch({
          type: "STOP_WAITTING",
        });
      }, 5000);
    }
  }, [popupStates]);

  return (
    <S.Wrapper className={popupStates.isWaitting ? "active" : ""}>
      <div className="bg-loading">
        <div className="content-loading">
          <div className="border-loading"></div>
          <div className="icon-loading"><img src={logo} alt=''/></div>
        </div>
      </div>
    </S.Wrapper>
  );
};

export default PopupCheck;
