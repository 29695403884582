/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:33:01
 * @modify date 2022-01-19 14:33:01
 * @desc [description]
 */

import styled from "styled-components";

// interface ItemProps {
//   bg?: any;
// }

export const Container = styled.div`
  width: 100px;
  left: calc(50% - 50px);
  height: auto;
  position: absolute;
  top: 20px;
  z-index: 99;
  background: #000;
  font-size: 12px;
  color: #fff;
  display: block;
  &.false {
    display: none;
  }
  .content {
    margin: -6px 1px 2px;
    position: relative;
    .box {
      position: absolute;
      top: 0px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid #cbcbcc;
      font-size: 600;
    }
    .text {
      line-height: 20px;
    }
  }
  .button {
    width: 100px;
    padding: 0px 4px 4px;
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }
`;
