/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-06-16 17:09:32
 * @modify date 2022-06-16 17:09:32
 * @desc [description]
 */

// Roboto
export const RobotoThin = require("./Roboto-Thin.ttf");
export const SCDream1 = require("./SCDream1.otf");
export const SCDream2 = require("./SCDream2.otf");
export const SCDream3 = require("./SCDream3.otf");
export const SCDream4 = require("./SCDream4.otf");
export const SCDream5 = require("./SCDream5.otf");
export const SCDream6 = require("./SCDream6.otf");
export const SCDream7 = require("./SCDream7.otf");
export const SCDream8 = require("./SCDream8.otf");
export const SCDream9 = require("./SCDream9.otf");
export const NotoSansKrBlack = require("./NotoSansKR-Black.ttf");
export const NotoSansKrBold = require("./NotoSansKR-Bold.ttf");
export const NotoSansKrLight = require("./NotoSansKR-Light.ttf");
export const NotoSansKrMedium = require("./NotoSansKR-Medium.ttf");
export const NotoSansKrRegular = require("./NotoSansKR-Regular.ttf");
export const NotoSansKrThin = require("./NotoSansKR-Thin.ttf");
