import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  display: none;
  &.active {
    display: block;
  }
  .bg-loading {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.4);
    z-index: 999;
  }

  .content-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .border-loading {
    position: absolute;
    width: 280px;
    height: 280px;
    border-bottom: 3px solid #ffffff;
    border-right: 2px solid #ffffff;
    z-index: 9999;
    border-radius: 50%;
    animation: loadinBodergAnimate 2s linear infinite;
    transition: all 2s;
    -webkit-animation: loadinBodergAnimate 2s linear infinite;
  }

  .icon-loading {
    animation: loadingAnimate 16s ease-in-out infinite;
    transition: all 2s;
    position: relative;
    font-size: 38px;
    color: #ffffff;
    font-family: "Montserrat-Regular";
  }

  @keyframes loadinBodergAnimate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loadingAnimate {
    0% {
      transform: scale(0.8);
    }
    10% {
      transform: scale(1);
    }
    20% {
      transform: scale(0.8);
    }
    40% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
`;
