/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 15:20:09
 * @modify date 2022-01-21 15:20:09
 * @desc [description]
 */

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: transparent;
  position: relative;
`;

export const Header = styled.div`
  width: 100%;
  height: 54px;
  background: #1b2b43;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  .icon {
    .fa {
      color: #fff;
      font-size: 25px;
      margin: 0 6px;
    }
  }
  .text {
    margin-top: 4px;
    font-size: 18px;
    color: #fff;
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #0d1320;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export const BoxInfo = styled.div`
  width: 100%;
  height: auto;
  border-top: 1px solid #617686;
  border-left: 1px solid #617686;
  border-right: 1px solid #617686;
  min-height: 35px;

  display: flex;
  align-items: center;
  .left {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
  }
  .right {
    width: 85%;
    border-left: 1px solid #617686;
    height: 39px;
    padding: 4px;
    input {
      height: 31px;
      padding: 5px;
      background: #333;
      border: 1px solid #999;
      font-size: 12px;
      color: #fff;
      :focus {
        outline: none;
      }
      &.input1 {
        width: 100%;
      }
      &.input2 {
        width: 140px;
      }
    }
    .button {
      width: 62px;
      margin-top: 2px;
    }
    .name {
      width: 140px;
      height: 31px;
      padding: 7px 5px;
      background: #333;
      border: 1px solid #999;
      font-size: 12px;
      color: #fff;
    }
  }
`;

export const BoxInfo2 = styled.div`
  width: 100%;
  height: auto;
  border-top: 1px solid #617686;
  border-left: 1px solid #617686;
  border-right: 1px solid #617686;
  display: flex;
  align-items: center;
  .left {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
  }
  .right {
    width: 85%;
    border-left: 1px solid #617686;
    height: 35px;
    padding: 4px;
    .button {
      width: 62px;
      height: 23px;
      margin-top: 2px;
    }
  }
`;

export const BoxBet = styled.div`
  width: 100%;
  height: auto;
  min-height: 39px;
  padding: 4px;
  border-top: 1px solid #617686;
  border-left: 1px solid #617686;
  border-right: 1px solid #617686;
`;

export const BoxNote = styled.div`
  width: 100%;
  height: auto;
  min-height: 39px;
  border: 1px solid #617686;

  display: flex;
  align-items: center;

  .left {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
  }
  .right {
    width: 85%;
    border-left: 1px solid #617686;
    height: 110px;
    padding: 4px;
    textarea {
      width: 100%;
      height: 102px;
      padding: 10px 5px;
      background: #333;
      border: 1px solid #999;
      font-size: 12px;
      color: #fff;
      :focus {
        outline: none;
      }
    }
  }
`;

export const BoxButton = styled.div`
  width: 100%;
  height: auto;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fff;
  .back {
    height: 38px;
    padding: 2px 10px 0;
    font-size: 15px;
  }
  button {
    height: 38px;
    padding: 2px 10px 0;
    border: solid 1px #268ca7;
    border-radius: 2px;
    background-color: #b90606;
    font-size: 15px;
  }
`;

export const BoxBetting = styled.div`
  overflow: auto;
  width: calc(100% - 10px);
  max-width: 1200px;
  height: 775px;
  background: #fff;
  position: absolute;
  left: 10px;
  top: 70px;
  z-index: 5;
  display: none;
  &.active {
    display: block;
  }
  padding: 4px;
`;


export const BetItem = styled.div`
  width: 100%;
  margin-bottom: 24px;
  color: #ffffff;
  div {
    font-family: "NotoSansKR-Regular";
  }
  .header {
    width: 100%;
    padding: 6px 0;
    background: #000;
    display: flex;
    align-items: center;
    .hd {
      height: auto;
      min-height: 42px;
      border: 1px solid #505050;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      flex-wrap: wrap;
    }
    .hd1 {
      width: calc(12% - 1px);
      gap: 4px;
    }
    .hd2 {
      width: 22%;
    }
    .hd3 {
      width: 6%;
    }
    .hd4 {
      width: calc(8% + 1px);
    }
  }
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1px;
    margin-bottom: 2px;
    .it {
      height: auto;
      min-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to top, #153b58, #304759);
      padding: 0 8px;
      border: 1px solid #1d2844;
      &.red_bg {
        background: linear-gradient(to top, #11576a, #2688a3);
      }
    }
    .it1 {
      width: calc(12% - 1px);
      font-family: "NotoSansKR-Black";
    }
    .it2 {
      width: calc(22% - 1px);
      img {
        width: 20px;
        margin: 2px 4px 0 0;
      }
    }
    .it22 {
      width: calc(22% - 1px);
      justify-content: space-between;
      img {
        width: 9px;
        margin: 0 8px 3px;
        &.hendi {
          margin: 0 8px 1px;
          width: 7.2px;
        }
      }
    }
    .it3 {
      width: calc(6% - 1px);
      font-family: "NotoSansKR-Black";
    }
    .it4 {
      width: 8%;
      &.lose {
        color: #f12b1c;
      }
      &.win {
        color: #3178f9;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1px;
    .ft {
      height: auto;
      min-height: 42px;
      display: flex;
      align-items: center;
      background: linear-gradient(to top, #153b58, #304759);
      padding: 0 8px;
      border: 1px solid #1d2844;
    }
    .ft1 {
      width: calc(92% - 1px);
      justify-content: flex-start;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 0;
      .ft1it {
        width: 20%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 4px;
        .name {
          font-family: "NotoSansKr-Regular";
        }
        .value {
          font-family: "NotoSansKr-Black";
        }
      }
    }
    .ft2 {
      width: 8%;
      justify-content: center;
      .bt {
        color: #f12b1c;
        border: 1px solid #f12b1c;
        padding: 4px;
        cursor: pointer;
      }
    }
  }
`;