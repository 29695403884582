import styled from "styled-components";

export const Wrapper = styled.button`
  width: 100%;
  width: calc((100% - 20px) / 3);
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #7c79ff;
  background: #382275;
  box-shadow: 3px 4px 4px 0px rgba(189, 137, 255, 0.54) inset,
    -3px -5px 4px 0px rgba(0, 0, 0, 0.25) inset;
  &:hover {
    color: #ff5d2d;
    border-radius: 10px;
    border: 1px solid #7c79ff;
    background: #020135;
  }
`;
