import { mainAxios } from "libs/axios";

interface GameRightProps {
  type: string;
}

export const GameRightApi = (payload: GameRightProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/games`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
