/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2020-09-29 09:56:05
 * @modify date 2020-09-29 09:56:05
 * @desc [description]
 */

import { getAlls, set, del } from "../IDBStorage"; // IDB
import { TABLE_USER, TABLE_USER_COLUMN } from "../IDBStorage/type";

import { GETME } from "./type";

const now = new Date(); // Get current date

/** Save value to localStorage
 * @name: name of localStorage
 * @data : data want to save
 * @ex_date : expiration date // if set param is true ==> Always there (No expiration)
 */
interface saveProps {
  name?: any;
  data?: any;
  ex_date?: any;
  type?: "idb" | "session" | "localStorage";
}

export const saveLocalStorage = ({ name, data, ex_date, type }: saveProps) => {
  if (type === "idb") {
    set(TABLE_USER, TABLE_USER_COLUMN, data);
  } else {
    ex_date = ex_date * 24 * 3600000;
    data = ex_date
      ? { value: data, expiry: "no" }
      : { value: data, expiry: now.getTime() + ex_date };

    if (type === "session") {
      sessionStorage.setItem(name, JSON.stringify(data));
    } else {
      localStorage.setItem(name, JSON.stringify(data));
    }
  }
};

/** Get data from localStorage
 * @name: name of localStorage
 * @type: get option value
 * + 0 ==> return value
 * + 1 ==> return express date
 */

interface getStoreDataProps {
  name?: any;
  typeGetData?: "User";
  type?: any;
}

export const getLocalStorage = async ({
  name,
  typeGetData,
  type,
}: getStoreDataProps) => {
  let s: any = null;
  if (typeGetData === "User") {
    const userData: any = await getAlls(TABLE_USER);
    // console.log(userData);
    
    s = userData[0];
    if (!s) {
      s = sessionStorage.getItem(GETME);
      s = JSON.parse(s)?.value;
    }
    return s;
  } else {
    s = localStorage.getItem(name)
      ? localStorage.getItem(name)
      : sessionStorage.getItem(name);

    if (!s === null) return null;
    if (s) s = JSON.parse(s);

    let dateSaved = new Date(s?.expiry);
    if (s && s["expiry"] !== undefined && now > dateSaved) {
      deleteLocaStorage(name);
      return null;
    }

    if (s && s["value"] !== undefined && type === 0) return s.value;
    if (s && s["expiry"] !== undefined && type === 1) return s.expiry;
  }
};

/** Delete item localStorage */
interface deleteStoreProps {
  name: string;
  typeDeleteData?: "User";
}

export const deleteLocaStorage = ({
  name,
  typeDeleteData,
}: deleteStoreProps) => {
  if (typeDeleteData === "User") {
    del(TABLE_USER, TABLE_USER_COLUMN);
  }
  localStorage.getItem(name)
    ? localStorage.removeItem(name)
    : sessionStorage.removeItem(name);
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
};

/** Delete all localStorage */
export const cleanLocaStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};
