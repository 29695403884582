import React from "react";
import footerImg from 'assets/images/footer.png'
import mbFooterImg from 'assets/images/mb-footer.png'
import { FooterWrapper } from "./style";
import useWindowSize from "hooks/useWindowSize";
const Footer: React.FC<{}> = () =>{
    const size = useWindowSize()
    return (
        <FooterWrapper>
            <div className="footer-baner">
                <img src={size.width && size.width > 500 ? footerImg: mbFooterImg} alt="" />
            </div>
            <div className="footer-text">
                ©2023 • LaLa. All Rights Reserved
            </div>
        </FooterWrapper>
    )
}

export default Footer
