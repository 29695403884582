import styled from "styled-components";

export const FooterWrapper = styled.div`
    padding-top: 150px;
    background: var(--Dark-Purple, #020135);

    .footer-baner{
        img{
            width: 100%;
            height: auto;
            @media screen and (max-width: 500px) {
                height: 100px;
            }
        }
    }
    .footer-text{
        padding: 36px 0;
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        @media screen and (max-width: 500px) {
            padding: 20px 0;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 500px) {
        padding-top: 90px;
    }
`
