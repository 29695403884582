import {useEffect, RefObject} from 'react'
const useOutSideClick = (ref: RefObject<HTMLElement>, callback: () => void) => {
  const handleClickOutside = (event: UIEvent) => {
    if (ref.current && !ref.current?.contains(event.target as Node)) {
      callback();
    }
  };
  useEffect(() =>{
    window.addEventListener('click', handleClickOutside)

    return () =>{
      window.removeEventListener('click', handleClickOutside)
    }
  }, [ref])
}

export default useOutSideClick;
