import { mainAxios } from "libs/axios";

interface BettingProps {
  type?: string | number;
  limit?: string | number;
  page?: string | number;
}

export const getBetting = (payload: BettingProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/betting`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface DeleteBettingProps {
  type?: string | number;
  ids?: any;
}

export const deleteBetting = (payload: DeleteBettingProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/betting/delete`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface BettingInfoProps {
  ids?: string;
}

export const getBettingInfo = (payload: BettingInfoProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/betting/info`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export interface CancleInfoProps {
  id: number;
}

export const cancleBetting = (payload: CancleInfoProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/mini/cancel`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
