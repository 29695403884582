import { mainAxios } from "libs/axios";

interface EventProps {
  limit?: string;
  page?:string | number
}

export const getEvent = (payload: EventProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/event`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface EventDetailProps {
  id?: string | number;
}

export const getEventDetail = (payload: EventDetailProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/event/${payload.id}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
