import styled from "styled-components";
// import { Button, Popup } from "components";
import { Modal } from "antd";

export const Wrapper = styled(Modal)`
  top: 35%;
  @media screen and (max-width: 1024px) {
    top: 25%;
  }
  @media screen and (max-width: 768px) {
    top: 20%;
  }

  &.custom-modal-notice {
    .ant-modal-body {
      min-height: 300px;
      /* padding: 32px; */
    }
  }
  .header-popup {
    display: none;
  }
  .ant-modal-body {
    /* padding: 32px; */
  }
  .ant-modal-content {
    padding: 20px;
    border-radius: 4px;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const Header = styled.div`
  height: 53px;
  border-bottom: 1.5px solid #08ceac;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close-icon {
    align-self: center !important;
  }
`;

export const Title = styled.div`
  font-family: "NotoSansKR-Bold";
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #08ceac;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  align-self: center !important;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    /* font-family: "NotoSansKR-Regular"; */
    font-size: 13px;
    color: #000;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.63; */
    letter-spacing: normal;
    /* text-align: center; */
    color: #525356;
    margin: 0 0px 20px;

    &.custom-modal-notice {
      margin: 24px 0px 19px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  .button {
    height: 26px;
    padding: 4px 12px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    &.ok {
      background: #09d;
      border: solid 1px #09d;
      color: white;
    }
    &.cancel {
      color: #888;
      border: solid 1px #ddd;
    }
  }
`;

export const ActionButton = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 100px;
  border: none;
  background-color: #08ceac;
  font-family: "NotoSansKR-Medium";
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  &.custom-notice {
    max-width: 250px;
    display: block;
    margin: auto;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

export const OKButton = styled.div`
  flex: 1;
  height: 50px;
  border-radius: 100px;
  border: none;
  font-family: "NotoSansKR-Medium";
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;

  &.not-account-money {
    min-width: 200px;
  }
`;

export const CancelButton = styled.div`
  flex: 1;
  height: 50px;
  border-radius: 100px;
  font-family: "NotoSansKR-Medium";
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;

  &.not-account-money {
    min-width: 120px;
    flex: unset;
  }
`;
