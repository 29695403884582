import React, { useEffect, useState } from "react"; //lazy
import { useHistory, useLocation } from "react-router-dom";

import { deleteLocaStorage, getLocalStorage } from "utils/localStorage";
import { UrlInternal } from "common/constants/endpoints";
// import { listMenu } from "components/Menu";
import { useDispatch, useSelector } from "react-redux";

import { meApi } from "apis";
import types from "store/actions/types";

import * as S from "./style";
interface Props {
  children?: any;
  noImageHeader?: boolean;
  type?: "smallBanner" | "default";
  typeContent?: "out" | "in";
}

const TemplateGame = ({ children }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location?.pathname;
  const userData: any = localStorage.getItem("User");
  const dataMe = useSelector((state: any) => state.user);

  const getMe = async () => {
    dispatch({ type: "WAITTING" });
    try {
      const res: any = await meApi();
      // console.log(res);
      if (res.result === false) {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
      } else {
        dispatch({ type: "STOP_WAITTING" });
        dispatch({
          type: types.GET_DATA_USER,
          dataUser: res.data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    }
  };

  useEffect(() => {
    if (!userData) {
      if (path !== "/register" && path !== "/login") {
        history.push(UrlInternal.LOGIN);
        console.log("jhjkfgadsfda");
      }
    } else {
      getMe();
    }
  }, [userData, path]);

  useEffect(() => {
    if (userData) {
      if (
        dataMe?.dataUser?.count_message &&
        dataMe?.dataUser?.count_message > 0 &&
        // !location.pathname.includes("message")
        !location.pathname.includes("/message") &&
        !location.pathname.includes("/partner")
      ) {
        setTimeout(
          () =>
            dispatch({
              type: types.POPUP_ACTION_OPEN,
              payload: {
                msg: `쪽지를 확인하세요.`,
                typePopup: "CONFIRM",
                actionText: "OK",
                closeLinkToPage: `${UrlInternal.MESSAGE}`,
              },
            }),
          500
        );
      }
    }
  }, [location, dataMe]);

  return <S.Wraper>{children}</S.Wraper>;
};

export default TemplateGame;
