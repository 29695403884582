import { mainAxios } from "libs/axios";

export const casinoListApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/casino/as`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getListCasino = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/as/casino`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface CasinoTypeProps {
  type: string;
  code: string | number;
}

export const getCasinoType = (payload: CasinoTypeProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/as/casino/gamelist`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface CasinoDetailProps {
  vendor: string;
  game_id: string | number;
}

export const casinoGameDetailApi = (payload: CasinoDetailProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/casino/as/launch`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface LaunchProps {
  vendor: string;
  type: string;
  game_code: string;
}

export const getLaunchCasino = (payload: LaunchProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/as/launch`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
