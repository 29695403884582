import React from 'react';
import styled from 'styled-components';
import Footer from 'components/Footer';

type Props = {
    children: React.ReactNode;
};

const Bg = styled.div`
    height: 100%;
`;
const Wrapper = styled.div`
    width: 100%;
    max-width: calc(1668px + 30px);
    margin:0 auto;
    padding: 0 15px;
    color: #fff;
`;
const NewContainer: React.FC<Props> = ({ children }) => {
    return (
        <>
            <Wrapper>{children}</Wrapper>
            <Footer />
        </>
    );
};

export default NewContainer;
