
import React, { useEffect, useRef, useState } from "react";
import { Menu, BackDrop } from './style'
import { UrlInternal } from "common/constants/endpoints";
import { useDispatch } from "react-redux";
import { miniAllApi } from "apis";
import { Link } from "react-router-dom";
import useOutSideClick from "hooks/useOutsideClick";
type Props = {
  onClose: () => void,
  show: boolean,
}

const FloatMenu: React.FC<Props> = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [listMiniGame, setListMiniGame] = useState<any>([]);
  const menuRef = useRef(null)
  const menuItems = [
    {
      id: 0,
      label: "홈",
      link: UrlInternal.HOME,
      color: '#ffb342'
    },
    {
      id: 1,
      label: "국내형 스포츠",
      link: UrlInternal.SPORT_SPECIAL_ROUTER,
      color: '#ffb342'

    },
    {
      id: 2,
      label: "실시간",
      link: UrlInternal.SPORT_LIVE_ROUTER,
      color: '#ffb342'

    },
    {
      id: 3,
      label: "라이브 카지노",
      link: UrlInternal.CASINO,
      color: '#ffb342'

    },
    {
      id: 4,
      label: "슬롯 머신",
      link: UrlInternal.SLOT_GAME,
      color: '#ffb342'

    },
    {
      id: 5,
      label: "파워볼",
      link: `${UrlInternal.MINI_GAME_DETAIL_ROUTER}${listMiniGame.length > 0 && listMiniGame[0].game_type
        }`,
      color: '#ffb342'

    },
    {
      id: 6,
      label: "공지/이벤트",
      link: UrlInternal.MESSAGE,

    },
    {
      id: 6,
      label: "경기결과",
      link: UrlInternal.HISTORY_SPORT_SOLO,

    },
    {
      id: 7,
      label: "배팅내역",
      link: UrlInternal.HISTORY_BETTING_SOLO,

    },
    {
      id: 8,
      label: "1:1 문의",
      link: UrlInternal.CUSTOMER_CENTER,
    },
  ]

  const getListMiniGame = async () => {
    dispatch({ type: "WAITTING" });
    try {
      const res: any = await miniAllApi();

      if (res?.result === false) {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
        return;
      }

      setListMiniGame(res);
      dispatch({ type: "STOP_WAITTING" });
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    } finally {
      dispatch({ type: "STOP_WAITTING" });
    }
  };
  useEffect(() =>{
    getListMiniGame()
  }, []);

  useOutSideClick(menuRef, onClose);
  
    return (
      <>
      <BackDrop />
      <Menu ref={menuRef} id="mobileMenu">
        <ul>
          {
            menuItems.map(e =>{
              return (
                <li>
                  <Link style={{color: e.color || '#fff'}} key={e.id} to={e.link}>{e.label}</Link>
                </li>
              )
            })
          }
        </ul>
      </Menu>
      </>
    )
  
}

export default FloatMenu
