import styled from "styled-components";

export const Wrapper = styled.button`
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 50px;
  border-radius: 10px;
  background: #ff5d2d;
  box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
    3px 3px 4px 0px rgba(255, 206, 170, 0.83) inset;
  @media screen and (max-width: 1200px) {
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
  }
  &:hover {
    border-radius: 10px;
    background: #e2342f;
    box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
      3px 3px 4px 0px rgba(255, 206, 170, 0.83) inset;
  }
`;
