import { mainAxios } from "libs/axios";

interface NoticeProps {
  limit?: string | number;
  page?: string | number;
}

export const getNotice = (payload: NoticeProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/broad`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface NoticeDetailProps {
  id?: string | number;
}

export const getNoticeDetail = (payload: NoticeDetailProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/broad/${payload.id}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
