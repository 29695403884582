import styled, { css } from "styled-components";
import theme from "common/style/styleSetup";
// import small_banner from "assets/images/_appointment/small_banner.png";
import bgImg from "../../assets/images/home/bg-homePage.png";
export const Wraper = styled.div<{ active?: any }>`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #010023;
  justify-content: space-between;
  
`;
export const Container = styled.div<{ active?: any }>`
  width: 100%;
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
`;

export const LeftMenu = styled.div`
  width: 260px;
  height: auto;
  max-height: -webkit-fill-available;
  position: absolute;
  /* max-height: calc(100vh - 110px); */
  top: 0;
  left: 12px;
  background: red;
  background: ${theme.bg[18]};
  padding: 5px 5px 15px;
  overflow: auto;
`;

export const Children = styled.div`
  width: calc(100% - 580px);
  background-color: transparent;
  margin: 0 auto;
  height: calc(100vh - 110px);
  overflow: auto;
`;

export const RightMenu = styled.div`
  width: 260px;
  height: auto;
  position: absolute;
  top: 0;
  right: 12px;
  max-height: -webkit-fill-available;
  overflow: auto;
`;

export const NewContainer = styled.div`
  width: 100%;
  // background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
   background-attachment: fixed; 
  background-position: top;
  /* background-size: auto; */
  /* background-clip: border-box; */
  background-size: 100%;
  /* max-width: calc(1668px + 30px); */
  /* margin: auto; */
  /* padding: 0 15px; */
`;
