/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 15:20:09
 * @modify date 2022-01-21 15:20:09
 * @desc [description]
 */

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: transparent;
  overflow: auto;
`;

export const Header = styled.div`
  width: 100%;
  min-height: 46px;
  background: #000;
  color: #fff;
  display: flex;
  border-bottom: 2px solid #fff;
  .left {
    width: 30%;
    padding: 4px 4px 4px 15px;
    display: flex;
    align-items: center;
    span {
      font-size: 15px;
      font-family: "NotoSansKr-Black";
    }
  }
  .right {
    width: 70%;
    display: flex;
    padding: 4px;
    .right-content {
      width: 100%;
      border: 1px solid #617686;
      display: flex;
      justify-content: space-between;
      .right1 {
        width: auto;
        display: flex;
        padding: 4px 6px;
        align-items: center;
        span {
          font-size: 13px;
          font-family: "NotoSansKr-Regular";
        }
      }
      .right2 {
        border-left: 1px solid #617686;
        width: 35px;
      }
    }
  }
`;
export const TabList = styled.div`
  width: 100%;
  background: #0d1320;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #5d8ec7;
  display: flex;
  align-items: center;
  gap: 3px;
  .item {
    width: 110px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #5d8ec7;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #337ab7;
    &.active {
      background: #354376;
      color: #fff;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #fff;
`;
export const ContentData = styled.div`
  width: 100%;
  background: #fff;
  overflow: auto;
  height: 670px;
  overflow: auto;
  border: 1px solid #617686;
  padding: 4px;
  margin-top: -1.5px;
  .box {
    width: 100%;
    min-width: 1184px;
    background: #000;
  }
`;

export const ItemBox = styled.div`
  width: 100%;
  padding: 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .item {
    width: 160px;
    height: 55px;
    cursor: pointer;
    color: #ffffff;
    padding: 3px 5px;
    background: linear-gradient(to top, #022b4c, #033046);
    border-radius: 3px;
    font-size: 16px;
    transition: 200ms all;
    div {
      width: 150px;
      height: 49px;
      border: solid 1px #268ca7;
      font-size: 16px;
      background: #3c5c81;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    :hover {
      color: #000;
      background: gold;
      transition: 200ms all;
    }
  }
`;

export const Bet = styled.div`
  width: 100%;
  font-size: 14px;
`;
export const BetItem = styled.div`
  width: 100%;
  margin-bottom: 24px;
  color: #ffffff;
  div {
    font-family: "NotoSansKR-Regular";
  }
  .header {
    width: 100%;
    padding: 6px 0;
    background: #000;
    display: flex;
    align-items: center;
    .hd {
      height: auto;
      min-height: 42px;
      border: 1px solid #505050;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      flex-wrap: wrap;
    }
    .hd1 {
      width: calc(12% - 1px);
      gap: 4px;
    }
    .hd2 {
      width: 22%;
    }
    .hd3 {
      width: 6%;
    }
    .hd4 {
      width: calc(8% + 1px);
    }
  }
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1px;
    margin-bottom: 2px;
    .it {
      height: auto;
      min-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to top, #153b58, #304759);
      padding: 0 8px;
      border: 1px solid #1d2844;
      &.red_bg {
        background: linear-gradient(to top, #11576a, #2688a3);
      }
    }
    .it1 {
      width: calc(12% - 1px);
      font-family: "NotoSansKR-Black";
    }
    .it2 {
      width: calc(22% - 1px);
      img {
        width: 20px;
        margin: 2px 4px 0 0;
      }
    }
    .it22 {
      width: calc(22% - 1px);
      justify-content: space-between;
      img {
        width: 9px;
        margin: 0 8px 3px;
        &.hendi {
          margin: 0 8px 1px;
          width: 7.2px;
        }
      }
    }
    .it3 {
      width: calc(6% - 1px);
      font-family: "NotoSansKR-Black";
    }
    .it4 {
      width: 8%;
      &.lose {
        color: #f12b1c;
      }
      &.win {
        color: #3178f9;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1px;
    .ft {
      height: auto;
      min-height: 42px;
      display: flex;
      align-items: center;
      background: linear-gradient(to top, #153b58, #304759);
      padding: 0 8px;
      border: 1px solid #1d2844;
    }
    .ft1 {
      width: calc(92% - 1px);
      justify-content: flex-start;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 0;
      .ft1it {
        width: 20%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 4px;
        .name {
          font-family: "NotoSansKr-Regular";
        }
        .value {
          font-family: "NotoSansKr-Black";
        }
      }
    }
    .ft2 {
      width: 8%;
      justify-content: center;
      .bt {
        color: #f12b1c;
        border: 1px solid #f12b1c;
        padding: 4px;
        /* cursor: pointer; */
      }
    }
  }
`;

export const Pagi = styled.div`
  width: 100%;
  padding: 24px 2px;
  /* font-size: 14px; */
  .ant-pagination-next,
  .ant-pagination-prev,
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-item {
    a {
      color: #ffffff;
    }
  }
  .ant-pagination-item-active {
    a {
      border: 1px solid #c5c5c5;
    }
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link-icon {
      color: #c5c5c590 !important;
    }
    .ant-pagination-item-ellipsis {
      color: #c5c5c590 !important;
      margin-top: 4px !important;
    }
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  background: #fff;
  height: 36px;
  padding: 4px;
  border: 1px solid #617686;
  margin-top: 12px;
  display: flex;
  gap: 6px;
  .button {
    height: 24px;
    padding: 0%;
    font-family: "NotoSansKr-Bold";
    font-size: 12px;
    color: #fff;
    background: #3c5c81;
    cursor: pointer;
    padding: 5px 6px;
    :hover {
      background: #268ca7;
    }
  }
`;
