import styled from "styled-components";
import theme from "common/style/styleSetup";
import common from "assets/images/templates/common.png";

export const Wrapper = styled.div`
  width: max-content;
  height: 26px;
  cursor: pointer;
  background-color: #3c5c81;
  border: solid 1px #268ca7;
  /* padding: 0 5px; */
  padding: 3px 5px 0;
  border-radius: 2px;
  transition: 200ms all;

  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;

  :hover {
    background: #268ca7;
    /* color: #000; */
    transition: 200ms all;
  }
`;
