/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:23:06
 * @modify date 2022-01-19 14:23:06
 * @desc [description]
 */

import React, { useEffect, useState } from "react"; //lazy

import { popupAPI } from "apis";

import * as S from "./style";

interface Props {
  data?: any;
}

//props: Props
const PopupConfirm = ({ data = [] }: Props) => {
  const userData: any = localStorage.getItem("User");
  const check: any = localStorage.getItem("Popup");
  const [item, setItem] = useState<string>("");
  const [showItem, setShowItem] = useState<any>(true);

  const getPopup = async () => {
    const res: any = await popupAPI();
    // console.log(res);
    if (res) {
      setItem(res?.data[0]?.board_content);
    }
  };
  // console.log(check);

  useEffect(() => {
    if (userData && !check) {
      getPopup();
    }
  }, [userData, check]);

  useEffect(() => {
    if (check) {
      setShowItem(false);
    }
  }, [check]);

  const close = () => {
    localStorage.setItem("Popup", "true");
    setShowItem(!showItem);
  };

  return (
    <S.Container className={`${showItem ? "" : "false"} main`}>
      <div className="img" dangerouslySetInnerHTML={{ __html: item ?? "" }} />
      <div className="content">
        <div className="box" onClick={close}>
          {" "}
        </div>
        <div className="text"> &ensp;&emsp; 오늘은 그만 보고싶습니다.</div>
      </div>
      <div className="button">
        <span onClick={close}>CLOSE</span>
      </div>
    </S.Container>
  );
};

export default PopupConfirm;
