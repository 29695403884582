import styled from "styled-components";

export const Wrapper = styled.button`
  height: 50px;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  border: 2px solid #7c79ff;
  background: #543db2;
  box-shadow: 3px 4px 4px 0px rgba(189, 137, 255, 0.54) inset,
    -3px -5px 4px 0px rgba(0, 0, 0, 0.25) inset;

  @media screen and (max-width: 1200px) {
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
  }
  &:hover {
    color: #ff5d2d;
    text-align: center;
    border: 1px solid #7c79ff;
    background: #020135;
  }
`;
