import React from "react"
import "./style.css"
import logo from 'assets/images/login/logo.png'

interface Props {
  overlay?: any
}

export default function Loading(props: Props) {
  const { overlay } = props

  return <div className="bg-loading">
    <div className="content-loading">
      <div className="border-loading"></div>
      <div className="icon-loading"><img src={logo} alt=''/></div>
    </div>
  </div>
}
