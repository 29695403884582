import React, { memo, useEffect, useState } from "react";
import Countdown from "react-countdown";

type Props = { data?: any };

const Completionist = () => <span>0:00</span>;

function CountdownItem({ data }: Props): React.ReactElement {

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span
          data-ftime={data.ftime}
          data-type={data.game_type}
          data-ptime={data.ptime}
          data-minustime={data.minustime}
          data-viewendtime={data.viewendtime}
        >
          {minutes}:{seconds > 9 ? seconds : `0${seconds}`}
        </span>
      );
    }
  };


  return (
    <Countdown
      key={data.game_type + new Date().toTimeString()}
      date={
        Date.now() + (data?.ftime - data.minustime - data.viewendtime) * 1000
      }
      renderer={renderer}
    />
  );
}

export default memo(CountdownItem);
