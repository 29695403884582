import types from "../actions/types";

interface DataType {
  game_round: number;
  rest_min: number;
  rest_sec: number;
  game_no: number;
  delayTime: number;
}

const defaultState: DataType = {
  game_round: 0,
  rest_min: 0,
  rest_sec: 0,
  game_no: 0,
  delayTime : 0,
}; 

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.GET_INFO_BET_MINIGAME:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
