import React, { useEffect, useState } from "react"; //lazy
import { useHistory, useLocation } from "react-router-dom";

import { deleteLocaStorage, getLocalStorage } from "utils/localStorage";
import { UrlInternal } from "common/constants/endpoints";
// import { listMenu } from "components/Menu";
import { useDispatch, useSelector } from "react-redux";

import { GETME } from "utils/localStorage/type";
import { getListCasino, getListSlot } from "apis";
import types from "store/actions/types";

import * as S from "./style";
import {
  HeaderPage,
  BoxLink,
  ListCasino,
  ListSlotGame,
  BoxInfo,
  CasinoList,
  FooterPage,
} from "components";

import  NewContainer  from "../container";

interface Props {
  children?: any;
  noImageHeader?: boolean;
  type?: "smallBanner" | "default";
  typeContent?: "out" | "in";
  outSideLayout?: React.ReactNode
}

const TemplateGame = ({ children, type = "default", outSideLayout }: Props) => {
  const dispatch = useDispatch();

  const getListDataCasino = async () => {
    dispatch({ type: "WAITTING" });
    try {
      const res: any = await getListCasino();
      // console.log(res);
      if (res?.result === false) {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
      } else {
        dispatch({ type: "STOP_WAITTING" });
        // setData(res);
        dispatch({
          type: types.CASINO_LIST,
          casinoList: res,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    }
  };
  const getListDataSlot = async () => {
    dispatch({ type: "WAITTING" });
    try {
      const res: any = await getListSlot();
      // console.log(res);
      if (res?.result === false) {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
      } else {
        dispatch({ type: "STOP_WAITTING" });
        // setData(res);
        dispatch({
          type: types.SLOT_GAME_LIST,
          slotGameList: res,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    }
  };

  useEffect(() => {
    getListDataCasino();
    getListDataSlot();
  }, []);

  return (
    <S.Wraper>
      <HeaderPage />
      {outSideLayout}
      <NewContainer>
        {children}
      </NewContainer>
      {/* <S.Container>
        <S.LeftMenu>
          <BoxLink />
          <ListCasino />
          <ListSlotGame />
        </S.LeftMenu>
        <S.Children>
          {children}
          <FooterPage />
        </S.Children>
        <S.RightMenu>
          <BoxInfo />
          <CasinoList />
        </S.RightMenu>
      </S.Container> */}
    </S.Wraper>
  );
};

export default TemplateGame;
