import styled from 'styled-components'
import { Modal, Input } from 'antd';

export const CustomModal = styled(Modal)`
  .ant-modal-content{
    background-color: transparent;
    padding: 0;
    position: relative;
  } 
  .ant-modal{
    width: 100% !important;
  }
`

