import styled from "styled-components";

export const Wrapper = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 0 12px;
  background: #020135;
  white-space: nowrap;

  .title {
    color: #ffb342;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  img {
    transition: all 0.2s ease;
    filter: unset;

  }
  &:hover, &.active {
    box-shadow: -2px 0px 0px 0px #7c79ff, 2px 0px 0px 0px #7c79ff;
    /* border-left: 2px solid #7c79ff; */
    background: linear-gradient(
      180deg,
      #543db2 0%,
      #7a4a8b 48.96%,
      #ff7a00 100%
    );
    .title {
      color: #fff;
    }
    img{
      filter: brightness(0) invert(1);
    }
  }
  
  /* .img {
    display: none;
  }
  .imgHover {
    display: block;
  } */
`;
