/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 09:13:29
 * @modify date 2022-01-21 09:13:29
 * @desc [description]
 */

import React, { useEffect, useState } from "react"; //lazy
import Marquee from "react-fast-marquee";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import moneyConvert from "utils/moneyConvert";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";

import { TemplateMypage } from "templates";
/** Call API */
import { getBetting, deleteBetting } from "apis";

import types from "store/actions/types";
import { UrlInternal } from "common/constants/endpoints";

import { Button1, Button2 } from "components";
import level from "assets/images/level01.png";
import hendi from "assets/images/customer/hendi.png";
import under from "assets/images/customer/under.gif";
import over from "assets/images/customer/over.gif";

import * as S from "./style";

interface Props {
  onClose?: any;
  onSelect?: any;
}

const dataBet = [{ id: 0 }, { id: 1 }, { id: 4 }, { id: 4 }];

function Betting({ onClose, onSelect }: Props): React.ReactElement {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [dataDelete, setDataDelete] = useState<any>([]);
  const [pagi, setPagi] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 10,
  });

  const getDataBetting = async (page: string | number) => {
    dispatch({ type: "WAITTING" });
    try {
      const res: any = await getBetting({
        type: "1",
        page: page,
        limit: 10,
      });
      // console.log(res);
      if (res.data) {
        let newData: any = [];
        for (let i = 0; i < res?.data?.length; i++) {
          const item = {
            key: i,
            id: res?.data[i].id,
            div: res?.data[i].bet_div,
            game_status: res?.data[i].bet_game_status,
            regdate: res?.data[i].bet_regdate,
            status: res?.data[i].bet_status,
            total: res?.data[i].bet_total,
            color: res?.data[i].event_color,
            name: res?.data[i].event_name,
            no: res?.data[i].event_no,
            odds: res?.data[i].event_odds,
            term: res?.data[i].event_term,
            cancel: res?.data[i].is_cancel,
            winmoney: res?.data[i].winmoney,
            winmoney_real: res?.data[i].winmoney_real,
            game_list: res?.data[i].game_list,
          };
          newData = [...newData, item];
        }
        const pagiItem = {
          page: res?.meta.current_page,
          pageSize: res?.meta.per_page,
          total: res?.meta.total,
        };
        setData(newData);
        setPagi({ ...pagi, ...pagiItem });
        dispatch({ type: "STOP_WAITTING" });
      } else {
        alert(res.message);
        dispatch({ type: "STOP_WAITTING" });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "STOP_WAITTING" });
    }
  };

  useEffect(() => {
    getDataBetting(1);
  }, []);

  const changeCheck = (e: any, id: number) => {
    if (dataDelete?.includes(id)) {
      const newData = dataDelete.filter((item: any) => item !== id);
      setDataDelete(newData);
    } else {
      setDataDelete([...dataDelete, id]);
    }
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    // console.log("Page: ", pageNumber);
    getDataBetting(pageNumber);
  };

  const selectItem = async () => {
    if (dataDelete.length === 0) {
      alert("배팅을 선택하세요.");
    } else {
      setDataDelete([]);
      let text: string = "";
      if (dataDelete?.length === 1) {
        text = `${dataDelete[0]}`;
      } else {
        for (let i = 0; i < dataDelete?.length; i++) {
          if (i === 0) {
            text = dataDelete[0];
          } else {
            text = text + "|" + dataDelete[i];
          }
        }
      }
      onSelect(text);
    }
  };
  const closeItem = () => {
    setDataDelete([]);
    onClose();
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.Header>
          <div className="left">
            <span>배팅내역첨부하기</span>
          </div>
          <div className="right">
            <div className="right-content">
              <div className="right1">
                <span>
                  게시판에 첨부하신 내역은 베팅내역에서 삭제하셔도 삭제되지
                  않습니다.
                </span>
              </div>
              <div className="right2" />
            </div>
          </div>
        </S.Header>
        <S.ContentData>
          <div className="box">
            <S.Bet>
              {data.map((v: any, i: number) => (
                <S.BetItem key={i}>
                  <div className="header">
                    <div className="hd hd1">
                      <input
                        type="checkbox"
                        checked={dataDelete?.includes(v.id)}
                        onChange={(e: any) => changeCheck(e, v.id)}
                      />
                      경기일시
                    </div>
                    <div className="hd hd2">리그명</div>
                    <div className="hd hd2">승 / 홈 / 언더</div>
                    <div className="hd hd3">무</div>
                    <div className="hd hd2 ">패 / 원정 / 오버</div>
                    <div className="hd hd4">점수</div>
                    <div className="hd hd4">결과</div>
                  </div>
                  {v.game_list.map((v1: any, i1: number) => (
                    <div className="item" key={i1}>
                      <div className="it it1">
                        {moment(v1?.game_date).format("MM/DD")} {v1?.game_time}
                      </div>
                      <div className="it it2">
                        <img alt=" " src={v1?.league_image} />
                        {v1?.league_name}
                      </div>
                      <div className={`it it22 ${v1?.class_div_0}`}>
                        <div className="ct">{v1?.home_name}</div>
                        <div className="ct">
                          {v1?.under_icon && (
                            <img
                              alt=" "
                              src={v1?.under_icon}
                              className={`${
                                v1?.under_icon.includes("icon-hendi")
                                  ? "hendi"
                                  : ""
                              }`}
                            />
                          )}
                          {v1?.win}
                        </div>
                      </div>
                      <div className={`it it3 ${v1?.class_div_2}`}>
                        {v1?.point_str}
                      </div>
                      <div className={`it it22 ${v1?.class_div_1}`}>
                        <div className="ct">
                          {v1?.loss}
                          {v1?.over_icon && (
                            <img
                              alt=" "
                              src={v1?.over_icon}
                              className={`${
                                v1?.under_icon.includes("icon-hendi")
                                  ? "hendi"
                                  : ""
                              }`}
                            />
                          )}
                        </div>
                        <div className="ct">{v1?.visit_name}</div>
                      </div>
                      <div className="it it4">{v1?.game_score}</div>
                      <div
                        className={`it it4 ${
                          v1?.bet_game_status === "적중" ? "win" : "lose"
                        }`}
                      >
                        {v1?.bet_game_status}
                      </div>
                    </div>
                  ))}
                  <div className="footer">
                    <div className="ft ft1">
                      <div className="ft1it">
                        <span className="name">배팅시간 :</span>
                        <span className="value">
                          {moment(v?.regdate).format("MM-DD HH:MM")}
                        </span>
                      </div>
                      <div className="ft1it">
                        <span className="name">배팅금액 :</span>
                        <span className="value">
                          {moneyConvert(v.total, true)} 원
                        </span>
                      </div>
                      <div className="ft1it">
                        <span className="name">배당율 :</span>
                        <span className="value">{v.div}</span>
                      </div>
                      <div className="ft1it">
                        <span className="name">예상적중금액 :</span>
                        <span className="value">
                          {moneyConvert(v.winmoney, true)} 원
                        </span>
                      </div>
                      <div className="ft1it">
                        <span className="name">적중금액 :</span>
                        <span className="value"> </span>
                      </div>
                    </div>
                    <div className="ft ft2">
                      <div className="bt">미적중</div>
                    </div>
                  </div>
                </S.BetItem>
              ))}
            </S.Bet>
            <S.Pagi>
              {data.length > 0 && (
                <Pagination
                  showQuickJumper={false}
                  total={pagi.total}
                  current={pagi.page}
                  onChange={onChange}
                />
              )}
            </S.Pagi>
          </div>
        </S.ContentData>
        <S.ButtonBox>
          <div className="button left" onClick={closeItem}>
            취소하기
          </div>
          <div className="button right" onClick={selectItem}>
            배팅내역첨부
          </div>
        </S.ButtonBox>
      </S.Content>
    </S.Wrapper>
  );
}

export default Betting;
