import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalLayout } from "./style";
import closeIcon from 'assets/icons/close-icon.svg'
import types from "store/actions/types";
import { Form } from "antd";
import TextButton from "components/TextButton";
import CustomInput from "components/Input";
import * as S from "pages/CustomerCenterPageRegister/style";
import moment from "moment";
import moneyConvert from "utils/moneyConvert";
import { createCustomerCenter, getBettingInfo } from "apis";
import Betting from "pages/CustomerCenterPageRegister/Betting";
import { UrlInternal } from "common/constants/endpoints";
import { useHistory } from "react-router-dom";

const ModalCustomerCenter: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const dataMe = useSelector((state: any) => state.user);
    const [dataItemBet, setDataItemBet] = useState<any>([]);
    const [betno, setBetno] = useState<string>("");
    const [isBetting, setIsBetting] = useState<boolean>(false);
    const history = useHistory();

    const showItem = useCallback(async () => {
        dispatch({ type: "WAITTING" });
        try {
            const res: any = await getBettingInfo({
                ids: betno,
            });
            // console.log(res);
            if (res?.result === false) {
                // console.log(res);
                dispatch({ type: "STOP_WAITTING" });
                alert(res?.message);
            } else {
                dispatch({ type: "STOP_WAITTING" });
                // alert(res.message);
                setDataItemBet(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }, [betno]);

    useEffect(() => {
        if (betno.length > 0) {
            showItem();
        }
    }, [betno]);

    const submit = async (val: any) => {
        console.log(val);
        
        if (!val.title || val.title.trim().length === 0) {
            alert("제목: 필수 입력입니다.");
            return
        } 
        if (!val.note || val.note.trim().length === 0) {
            alert("내용: 필수 입력입니다.");
            return
        }
        dispatch({ type: "WAITTING" });
        // console.log(title, note, betno);
        try {
          const res: any = await createCustomerCenter({
            board: "board09",
            board_html: "1",
            qString: "board=board01",
            board_field9: betno,
            board_subject: val.title.trim(),
            board_content: val.note.trim(),
          });
          // console.log(res);
          if (res.result) {
            // console.log(res);
            history.push(`${UrlInternal.CUSTOMER_CENTER_DETAIL_ROUTER}${res?.id}`);
            dispatch({ type: "STOP_WAITTING" });
            dispatch({ type: types.CLOSE_MODAL });

            alert(res.message);
          } else {
            dispatch({ type: "STOP_WAITTING" });
            alert(res.message);
          }
        } catch (error) {
          console.log(error);
        }
    }
    const onClose = () => {
        setIsBetting(false);
    };
    const onSelect = async (e: any) => {
        // console.log(e)
        setBetno(e);
        setIsBetting(false);
    };
    return (
        <ModalLayout>
            <img className="icon" onClick={() => dispatch({ type: types.CLOSE_MODAL })} src={closeIcon} alt="" />
            <S.BoxBetting className={isBetting ? "active" : ""}>
                <Betting onClose={onClose} onSelect={onSelect} />
            </S.BoxBetting>
            <Form onFinish={submit}>
                <div className="form-item">
                    <label htmlFor="">제목 <span>*</span></label>
                    <Form.Item name='title'>
                        <CustomInput placeHolder="제목" />
                    </Form.Item>
                </div>
                <div className="form-item">
                    <label htmlFor="">작성자</label>
                    <Form.Item name='name' initialValue={dataMe.dataUser.mb_name}>
                        <CustomInput readonly initValue={dataMe.dataUser.mb_name} />
                    </Form.Item>
                </div>
                <div className="form-item">
                    <label htmlFor="">배팅첨부</label>
                    <TextButton onClick={() => setIsBetting(true)}>배팅첨부</TextButton>
                    <S.BoxBet className="items-bet">
                        {dataItemBet?.map((v: any, i: number) => (
                            <S.BetItem key={i}>
                                <div className="header">
                                    <div className="hd hd1">
                                        <input
                                            type="checkbox"
                                        // checked={dataDelete?.includes(v.id)}
                                        // onChange={(e: any) => changeCheck(e, v.id)}
                                        />
                                        경기일시
                                    </div>
                                    <div className="hd hd2">리그명</div>
                                    <div className="hd hd2">승 / 홈 / 언더</div>
                                    <div className="hd hd3">무</div>
                                    <div className="hd hd2 ">패 / 원정 / 오버</div>
                                    <div className="hd hd4">점수</div>
                                    <div className="hd hd4">결과</div>
                                </div>
                                {v.game_list.map((v1: any, i1: number) => (
                                    <div className="item" key={i1}>
                                        <div className="it it1">
                                            {moment(v1?.game_date).format("MM/DD")} {v1?.game_time}
                                        </div>
                                        <div className="it it2">
                                            <img alt=" " src={v1?.league_image} />
                                            {v1?.league_name}
                                        </div>
                                        <div className={`it it22 ${v1?.class_div_0}`}>
                                            <div className="ct">{v1?.home_name}</div>
                                            <div className="ct">
                                                {v1?.under_icon && (
                                                    <img
                                                        alt=" "
                                                        src={v1?.under_icon}
                                                        className={`${v1?.under_icon.includes("icon-hendi")
                                                                ? "hendi"
                                                                : ""
                                                            }`}
                                                    />
                                                )}
                                                {v1?.win}
                                            </div>
                                        </div>
                                        <div className={`it it3 ${v1?.class_div_2}`}>
                                            {v1?.point_str}
                                        </div>
                                        <div className={`it it22 ${v1?.class_div_1}`}>
                                            <div className="ct">
                                                {v1?.loss}
                                                {v1?.over_icon && (
                                                    <img
                                                        alt=" "
                                                        src={v1?.over_icon}
                                                        className={`${v1?.under_icon.includes("icon-hendi")
                                                                ? "hendi"
                                                                : ""
                                                            }`}
                                                    />
                                                )}
                                            </div>
                                            <div className="ct">{v1?.visit_name}</div>
                                        </div>
                                        <div className="it it4">{v1?.game_score}</div>
                                        <div
                                            className={`it it4 ${v1?.bet_game_status === "적중" ? "win" : "lose"
                                                }`}
                                        >
                                            {v1?.bet_game_status}
                                        </div>
                                    </div>
                                ))}
                                <div className="footer">
                                    <div className="ft ft1">
                                        <div className="ft1it">
                                            <span className="name">배팅시간 :</span>
                                            <span className="value">
                                                {moment(v?.regdate).format("MM-DD HH:MM")}
                                            </span>
                                        </div>
                                        <div className="ft1it">
                                            <span className="name">배팅금액 :</span>
                                            <span className="value">
                                                {moneyConvert(v.total, true)} 원
                                            </span>
                                        </div>
                                        <div className="ft1it">
                                            <span className="name">배당율 :</span>
                                            <span className="value">{v.div}</span>
                                        </div>
                                        <div className="ft1it">
                                            <span className="name">예상적중금액 :</span>
                                            <span className="value">
                                                {moneyConvert(v.winmoney, true)} 원
                                            </span>
                                        </div>
                                        <div className="ft1it">
                                            <span className="name">적중금액 :</span>
                                            <span className="value"> </span>
                                        </div>
                                    </div>
                                    <div className="ft ft2">
                                        <div className="bt">미적중</div>
                                    </div>
                                </div>
                            </S.BetItem>
                        ))}
                    </S.BoxBet>
                </div>
                <div className="form-item">
                    <label htmlFor="">내용 <span>*</span></label>
                    <Form.Item name='note'>
                        <textarea placeholder="내용" />
                    </Form.Item>
                </div>
                <TextButton type="submit">문의하기</TextButton>
            </Form>
        </ModalLayout>
    )
}

export default ModalCustomerCenter
 