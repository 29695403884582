import styled from "styled-components";

export const CustomButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  transition: all 0.3s;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  @media screen and (max-width: 425px) {
    font-size: 16px;
    
  }
  &.light {
    background: var(--Orange, #ff5d2d);
    box-shadow: -3px -3px 4px 0px rgba(0, 0, 0, 0.25) inset,
      3px 3px 4px 0px rgba(255, 206, 170, 0.83) inset;
    &:hover {
      background: #e2342f;
    }

    &.active {
      background: #e2342f;
    }
  }
  &.dark {
    border: 2px solid var(--Stroke-Menu-button, #7c79ff);
    background: var(--Light-Purple, #543db2);
    box-shadow: 3px 4px 4px 0px rgba(189, 137, 255, 0.54) inset,
      -3px -5px 4px 0px rgba(0, 0, 0, 0.25) inset;
    &:hover {
      color: var(--Orange-Yellow, #ffb342);
      background: var(--Dark-Purple, #020135);
    }
    &.active{
      color: var(--Orange-Yellow, #FF5D2D);
      background: var(--Dark-Purple, #020135);
    }
  }
`;
